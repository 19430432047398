<script setup lang="ts">
import { ref, watch, defineEmits, toRefs } from 'vue';
import { Product } from '@/interfaces/api';
import { watchEffect } from 'vue';
const emit = defineEmits(['update:dialog', 'update:quantity']);


const props = defineProps({
  dialog: {
    type: Boolean,
    required: true,
    default: false,
  },
  product: {
     type: Object as () => Product,
     required: true
   },
});

const { dialog, product } = toRefs(props);


const quantity = ref(0);

function closeDialog(actionType: string = 'confirm') {
    // Emit the updated quantity and close the dialog
    if (actionType === 'cancel') {
      // open.value = false;
      emit('update:dialog', false);
      return    
    }

    if (quantity.value > 0) {
      // Close the dialog
      // open.value = false;
  
      // Reset the quantity to 1
      quantity.value = 0;
  
      // Emit the dialog update to the parent component
      emit('update:dialog', false);
    }
}

function confirmQuantity() {
  // Here, you can handle the confirmed quantity, such as updating the product list
  if (quantity.value > 0) {
      emit('update:quantity', quantity.value);
  }
  closeDialog();
}
// const open = ref(false);
// watchEffect(() => {
//   debugger
//   open.value = dialog.value;
// });
// whatch(() => dialog.value, (value) => {
//   open.value = value;
// }, {deep: true});
</script>

<template>
  <v-dialog
    v-if="product?.name"
    v-model="dialog"
    persistent
    max-width="290"
  >
    <v-card>
      <v-card-text>
        <v-card-text>{{ product.name }} | QTD: {{ product.stock_quantity }} </v-card-text>
        <v-text-field
          v-model="quantity"
          :autofocus="true"
          label="Quantity"
          type="number"
          min="1"
          @keyup.enter="confirmQuantity"
          @keyup.esc="closeDialog('cancel')"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          @click="closeDialog('cancel')"
        >
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  