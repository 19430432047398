import { createApp } from "vue"
import { createPinia } from "pinia"
import App from "./App.vue"
import "./registerServiceWorker"
import router from "./router"
import vuetify from "@/plugins/vuetify"
import "./tailwind.css"
import "vuetify/dist/vuetify.min.css"
import { VueQueryPlugin } from "@tanstack/vue-query"
const pinia = createPinia()
createApp(App)
  .use(vuetify)
  .use(pinia)
  .use(router)
  .use(VueQueryPlugin)
  .mount("#app")
