<script setup lang='ts'>
import API from "@/plugins/axios"
import { Order } from "@/interfaces/api"
import { format } from 'date-fns'
import { ref, onMounted, computed, watch } from 'vue'
import VButton from "@/components/VButton.vue"
import router from "@/router";
import ExpendableComponent from "@/components/ExpendableComponent.vue"
import SelectFilter from "@/components/SelectFilter.vue"
import { MONTHS } from "@/constants"
import { useWhatsapp } from "@/api/whatsapp"
const orders = ref<Order[]>([])
const currentMonth = ref<number>(new Date().getMonth())
const { whatsappService } = useWhatsapp()
const isProcessing = ref<boolean>(false)

onMounted(() => {
 API.get('order/orders', { params: {
  month_number: currentMonth.value
 }})
  .then((response) => {
    orders.value = (response.data.results as Order[])
  })
  .catch((error) => {
    console.error(error)
  })
})

const savePurchase = (orderId: number) => {
  const confirmed = confirm('Deseja confirmar a venda?')
  if (!confirmed) return
  API.post('purchase/save-purchase', { order_id: orderId }).then(() => {
    router.push({ name: 'purchases' })
  }).catch((e) => {
    console.error(e)
  
  })
}

const total = computed(()=>{
  return orders.value.reduce((acc, item) => {
    acc = Number(acc)
    return Number((acc + Number(item.total_price)).toFixed(2))
  }, 0)
})

const currentMonthName = computed(() => {
  return MONTHS[currentMonth.value]?.label
})

watch(currentMonth, () => {
  API.get('order/orders', { params: {
    number_month: currentMonth.value
 }})
  .then((response) => {
    orders.value = (response.data.results as Order[])
  })
  .catch((error) => {
    console.error(error)
  })
})

async function sendEmail(orderId: number) {
  const confirmed = confirm('Deseja enviar o e-mail?')
  if (!confirmed) return
  isProcessing.value = true;
  const result = await API.post('order/send-email', { order_id: orderId })
  if (result.status === 200) {
    alert('E-mail enviado com sucesso!')
  } 
  isProcessing.value = false
}

async function sendWhatsapp(orderId: number) {
  isProcessing.value = true;
  const result = await whatsappService.sendOrder(orderId)
  if (result) alert("Orçamento enviado!")
  isProcessing.value = false;
}

async function printOrder(orderId: number) {
  isProcessing.value = true;
        try {
        const response = await API.get(`order/print-order/`, {
            params: { order_id: orderId },
            responseType: 'blob'  // Ensure Axios is set to handle the response as a Blob
        });
        const fileURL = window.URL.createObjectURL(response.data);
        const printWindow = window.open("");
        if (printWindow) {
            // Listen for the load event which signifies that the document is fully loaded
            printWindow.addEventListener('load', function() {
                printWindow.print();   // Trigger the print dialog
                setTimeout(() => {
                    printWindow.close(); // Close the window after printing
                }, 500);  // Delay the close to ensure printing dialog has initiated
            });

            // Set the content of the new window
            printWindow.document.write(`
                <html>
                    <head>
                        <title>Print</title>
                    </head>
                    <body>
                        <embed src="${fileURL}" width="100%" height="100%" type="application/pdf">
                    </body>
                </html>
            `);
            printWindow.document.close();
        }
    } catch (error) {
        console.error('Failed to fetch and print PDF:', error);
    }
  isProcessing.value = false
}

</script>
<template>  
  <v-container>
    <div class="md:grid md:grid-cols-12 text-slate-800 p-6 flex justify-start">
      <span class="md:text-6xl text-3xl md:col-span-1">R$</span>
      <span class="md:text-6xl text-3xl md:col-span-2 md:mr-0 mr-6">{{ total }}</span>      
      <span class="md:text-6xl text-3xl md:col-span-6 uppercase" />      
      <span class="md:text-6xl text-3xl md:col-span-2 uppercase">{{ currentMonthName }}</span>      
    </div> <hr>
    <SelectFilter
      v-model="currentMonth"
      :placeholder="`Escolha o mês`"
      :options="MONTHS"
    />
    <v-progress-linear
      v-if="isProcessing"
      color="slate"
      indeterminate
    />
    <div class="text-start p-8">
      <div
        v-for="order in orders"
        :key="order.id"
        class="p-4 border-b-2 border-slate-300 md:flex md:justify-between"
      >
        <div>
          <div>
            <span class="text-3xl text-slate-600">ORÇAMENTO <span class="font-bold">{{ order.id }}</span></span>
          </div>
          <div class="w-full">
            <p>Cliente: {{ order.customer?.first_name }}</p>
            <p>Valor total: R$ {{ order.total_price }}</p>
            <p>Desconto: {{ order.discount || 0 }}%</p>
            <!-- <p>Itens: {{ order.products.length }}</p> -->
            <p>Data: {{ format(order.created_at, "dd-MM-yyyy") }}</p>
            <ExpendableComponent
              items-label="Produtos"
              :purchase-or-order="order"
            />
          </div>
        </div>
        <div>
          <div class="w-full">
            <VButton       
              class="w-full ml-0"       
              label-text="Confirmar Venda"
              @click="savePurchase(order.id)"
            />
          </div>
          <div>
            <VButton
              class="w-full ml-0"       
              label-text="Enviar por e-mail"
              @click="sendEmail(order.id)"
            />
          </div>
          <div>
            <VButton             
              class="w-full ml-0"       
              label-text="Enviar por whatsapp"
              @click="sendWhatsapp(order.id)"
            />
          </div>
          <div>
            <VButton             
              class="w-full ml-0"       
              label-text="Imprimir"
              @click="printOrder(order.id)"
            />
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>
