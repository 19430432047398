<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang='ts'>
import { Customer } from "@/interfaces/api"
import customerApi from "@/api/customer"
import { ref, onMounted, watch } from 'vue'
import VButton from "@/components/VButton.vue"
import CreateAndUpdate from "./CreateAndUpdate.vue"
import LastPurchases from "./LastPurchases.vue"
import { CREATE_CUSTOMER } from "@/constants"
import { useUserStore } from "@/store/user"
import { useFocus } from '@vueuse/core'

const { userPermissions } = useUserStore()
const customers = ref<Customer[]>([])
const search = ref("")
const searchInput = ref<HTMLInputElement | null>(null)
const openCreateAndUpdate = ref<boolean>(false)
const customerToBeUpdated = ref<Customer | null>(null)
const customerToReadPurchases = ref<Customer | null>(null)
const canCreateCustomer = CREATE_CUSTOMER.some((requirerPermission) => userPermissions?.includes(requirerPermission))
const queryCustomersApi = (search: string = "") => {
  customerApi.filterCustomers({ search: search})
    .then((response) => {
      customers.value = response
    })
    .catch((error) => {
      console.error(error)
    })
}

watch(search, (newValue) => {
  queryCustomersApi(newValue)
})
useFocus(searchInput, { initialValue: true })
const updateCustomer = (customer: Customer) => {
  openCreateAndUpdate.value = true
  customerToBeUpdated.value = customer
}

const listLastPurchases = (customer: Customer) => {
  customerToReadPurchases.value = customer
}
</script>
<template>
  <v-container>
    <!-- General information-->
    <div class="w-full md:flex md:justify-between items-start text-slate-800">
      <span class="text-6xl">Clientes</span>
      <span class="text-xl w-full md:w-auto">
        <VButton
          v-if="canCreateCustomer"
          class="w-full !ml-0"
          label-text="Cadastrar novo cliente"
          @click="openCreateAndUpdate = true"
        >
          <template #icon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 text-white"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>

          </template>
        </VButton>
      </span>
    </div> <hr>
    <div>
      <input
        ref="searchInput"
        v-model="search"
        type="text"
        class="w-full p-4 border-b-2 border-slate-300 border"
        placeholder="Pesquisar cliente"
      >
    </div>
    <div class="text-start mt-4 w-full">
      <div
        v-for="customer in customers"
        :key="customer.id"
        class="p-4 border-b-2 border-slate-300  bg-slate-100 w-full mb-3"
      >
        <div class="space-y-1 md:flex md:justify-between">
          <div>
            <div>
              <span class="text-3xl text-slate-600 uppercase"><strong>{{ customer.first_name }} {{ customer.last_name }}</strong></span>
            </div>
            <div class="space-y-2 text-xl mt-5">
              <p v-if="customer.cpf">
                CPF: <span class="font-bold">{{ customer.cpf }}</span>
              </p>
              <p v-if="customer.rg">
                RG: <span class="font-bold">{{ customer.rg }}</span>
              </p>
              <p v-if="customer.phone">
                Telefone: <span class="font-bold">{{ customer.phone }}</span>
              </p>
              <p v-if="customer.email">
                E-mail: <span class="font-bold">{{ customer.email }}</span>
              </p>
              <p v-if="customer.address">
                Endereço: <span class="font-bold">{{ customer.address }}</span>
              </p>
            </div>
          </div>        
          <div>
            <VButton
              class="w-full !ml-0"
              label-text="Atualizar Cadastro"
              @click="() => updateCustomer(customer)"
            >
              <template #icon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                  />
                </svg>
              </template>
            </VButton>
            <VButton
              class="w-full !ml-0"
              label-text="Ver últimas compras"
              @click="() => listLastPurchases(customer)"
            >
              <template #icon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                  />
                </svg>
              </template>
            </VButton>
          </div>
        </div>
      </div>
    </div>
  </v-container>
  <CreateAndUpdate
    :open="openCreateAndUpdate"
    :customer-to-update="customerToBeUpdated"
    @update:open="(value: boolean) => openCreateAndUpdate = !openCreateAndUpdate"
  />
  <LastPurchases
    :open="customerToReadPurchases !== null"
    :customer="customerToReadPurchases"
    @update:open="(value: boolean) => customerToReadPurchases = !value ? null : customerToReadPurchases"
  />
</template>
