<script setup lang="ts">
import { toRefs } from 'vue';
const emit = defineEmits(['update:modelValue']);
import { useVModel } from '@vueuse/core'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: false,
    default: false,
  },
  maxWidth: {
    type: String,
    required: false,
    default: '800',
  },
  maxHeight: {
    type: String,
    required: false,
    default: 'h-96',
  }
});
const { maxWidth, maxHeight } = toRefs(props);

const open = useVModel(props, 'modelValue', emit);

function close() {
    open.value = false;
}
</script>

<template>
  <v-dialog
    v-model="open"
    persistent
    :max-width="maxWidth"
  >
    <v-sheet
      class="flex flex-col items-center w-full"
      :class="maxHeight"
    >
      <div
        class="self-end mr-4 mt-3 cursor-pointer hover:animate-spin"
        @click="close"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-7 h-7"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </div>
      <slot name="title">
        <div class="pt-4">
          <span class="md:text-3xl" />
        </div>
      </slot>
      <slot name="content">
        <div class="md:text-xl">
          teste
        </div>
      </slot>
    </v-sheet>
  </v-dialog>
</template>
  