import { defineStore } from "pinia"
import { computed } from "vue"
import { User } from "@/interfaces/api"
import { useStorage } from "@vueuse/core"
export const useUserStore = defineStore("user", () => {

  // State
  const userData = useStorage<User | null>("user", null, undefined, {
    serializer: {
      read: (v: string) => (v ? JSON.parse(v) : null),
      write: (v: User | null) => JSON.stringify(v),
    },
  })
  const token = useStorage<string | null>("token", null)
  
  
  // Mutations
  const setCurrentUser = (user: User) => {
    userData.value = user
  }
  const setToken = (tokenValue: string) => {
    token.value = tokenValue
  }
  
  // Getters
  const currentUser = computed(() => {
    return userData.value
  })
  const isLoggedIn = computed(() => !!userData.value)
  const userPermissions = computed(() => userData.value?.permissions)
  const userGroups = computed(() => userData.value?.groups)
  const isAdmin = computed(() => userData.value?.access_level === "admin")
  return {
    setCurrentUser,
    setToken,
    currentUser,
    isLoggedIn,
    userPermissions,
    userGroups,
    isAdmin,
  }
})
