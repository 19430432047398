<script lang="ts">
export default {
  name: 'SelectFilter',
}
</script>
<template>
  <div>
    <label>{{ label }}</label>
    <v-select
      v-model="selectedOption"
      :items="options"
      :placeholder="placeholder"
      :item-title="option => option.label"
    />
    <!-- @change="emitSelectedOption" -->
  </div>
</template>

<script setup lang="ts">
import { useVModel } from '@vueuse/core';
import { defineProps, defineEmits } from 'vue';

const emit = defineEmits(['update@modelValue']);

const props = defineProps({
    options: {
        type: Array as () => { label: string; value: number }[],
        required: true,
    },
    placeholder: {
        type: String,
        default: '',
    },
    label: {
        type: String,
        default: '',
    },
    modelValue: {
        type: Number,
        required: true,
    },
});

 
const selectedOption = useVModel(props, 'modelValue', emit);

</script>

<style scoped>
/* Add your custom styles here */
</style>