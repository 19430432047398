import API from "@/plugins/axios";
import { User } from "@/interfaces/api";
import { useUserStore } from "@/store/user";

interface loginParams {
    username: string;
    password: string;
}

class UserApi {
    private url: string;
    constructor() {
        this.url = "/users/login/";        
        
    }

    async login(params: loginParams): Promise<User>{
        const useStore = useUserStore();
        const result = await API.post(this.url, { ...params });        
        useStore.setCurrentUser((result?.data?.user) as User)
        useStore.setToken((result?.data?.token) as string)
        location.href = "/";
        return result?.data?.user as User; // Linting error
    }
}

export default new UserApi();