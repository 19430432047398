import API from "@/plugins/axios";
import { Customer } from "@/interfaces/api";

interface searchParams{
    search: string;
}

class CustomerAPI {
    private url: string;
    private queryUrl: string;
    constructor() {
        this.url = "/customers";
        this.queryUrl = "/customer/list/";
    }

    async filterCustomers(params: searchParams): Promise<Customer[]>{
        const result = await API.get(this.queryUrl, { params });
        return result?.data?.results as Customer[];
    }
}

export default new CustomerAPI();