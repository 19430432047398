<script lang="ts">
export default {
  name: 'ExpendableComponent',
}
</script>
<script setup lang='ts'>
import { Product, ProductOrder, Order, Purchase } from "@/interfaces/api"
import { toRefs, ref } from "vue";

const props = defineProps({
  purchaseOrOrder: {
    type: Object as () => Order | Purchase,
    required: true
  },
  itemsLabel: {
    type: String,
    required: true,
    default: "Itens vendidos"
  }
})


const { purchaseOrOrder, itemsLabel } = toRefs(props)
const products =ref<ProductOrder[] | Product[]>([]);
if ('products' in purchaseOrOrder.value) {
  products.value = purchaseOrOrder.value.products;
} else if ('product_info' in purchaseOrOrder.value) {
  products.value = purchaseOrOrder.value.product_info
}


const calcTotalItems = (products: Array<ProductOrder>): number => {
  return products.reduce((acc: number, item) => {
    return acc + item.quantity
  }, 0)
}
</script>
<template>
  <v-expansion-panels class="mt-3">
    <v-expansion-panel>
      <v-expansion-panel-title
        block
        class="m-0 p-2 bg-slate-100"
      >
        <template #default="{ expanded }">
          <span>{{ itemsLabel }}: <strong class="text-lg">{{ calcTotalItems((products) as Array<ProductOrder>) }}</strong></span>
          <div
            v-if="expanded"
            key="0"
          >
            <div
              div
              class="space-y-2"
            >
              <div
                v-for="product in (products) as Array<ProductOrder>"
                :key="product.id"
                class="border-b md:ml-20 border-slate-300 grid grid-cols-9  space-x-4"
              >
                <span class="col-span-2">{{ product.name }}</span>
                <span class="col-span-1">|</span>
                <span class="col-span-1">{{ product.quantity < 10 ? `0${product.quantity}` : product.quantity }}</span>
                <span class="col-span-1">|</span>
                <span class="col-span-4 text-slate-800 font-semibold">R$ {{ product.price_sale }}</span>
              </div>
            </div>
          </div>
        </template>
      </v-expansion-panel-title>
    </v-expansion-panel>
  </v-expansion-panels>
</template>