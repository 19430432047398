<script setup lang="ts">
import { ref, } from "vue"
import { useMutation } from "@tanstack/vue-query"
import UserApi from "@/api/user"
const email = ref("")
const password = ref("")


const { mutate, error, isError, isSuccess, data } = useMutation({
  mutationFn: () =>
    UserApi.login({
      username: email.value,
      password: password.value,
    }),
})
function login() {
  mutate()
}
</script>


<template>
  <div class="w-96 m-auto rounded-3xl bg-slate-500 p-4 md:mt-24">
    <h2 class="text-xl  text-white">
      Login
    </h2>
    <hr class="my-4 border bg-white">
    <div class="w-full  h-60">
      <form
        @submit.prevent="login"
      >
        <input
          v-model="email"
          type="text"
          placeholder="Usuário"
          class="w-full bg-white border border-gray-300 p-2 rounded-md text-center mb-6"
          required
        >
        <input
          v-model="password"
          type="password"
          placeholder="Password"
          class="w-full bg-white border border-gray-300 p-2 rounded-md text-center mb-6"
          required
        >
        <button
          type="submit"
          class="w-full border mt-6 border-gray-300 p-2 rounded-md text-center bg-white text-blac"
        >
          Entrar
        </button>
        <div
          v-if="isError"
          class="p-4 text-white"
        >
          {{ error?.message }}
        </div>
        <div
          v-if="isSuccess"
          class="p-4 text-white"
        >
          {{ data?.username }} Logado com sucesso!
        </div>
      </form>
    </div>
    <p v-if="error">
      {{ error }}
    </p>
  </div>
</template>
