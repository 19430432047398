import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", null, _toDisplayString($props.label), 1 /* TEXT */),
    _createVNode(_component_v_select, {
      modelValue: $setup.selectedOption,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedOption) = $event)),
      items: $props.options,
      placeholder: $props.placeholder,
      "item-title": option => option.label
    }, null, 8 /* PROPS */, ["modelValue", "items", "placeholder", "item-title"]),
    _createCommentVNode(" @change=\"emitSelectedOption\" ")
  ]))
}