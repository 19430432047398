<script setup lang='ts'>
import GeneralPopUp from '@/components/GeneralPopUp.vue';
import { reactive, watch } from 'vue';
import { Customer } from '@/interfaces/api';
import VButton from '@/components/VButton.vue';
import API from '@/plugins/axios';
import { toRefs } from 'vue';
import { watchEffect } from 'vue';

const emit = defineEmits(['update:open']);
const form = reactive<Customer>({
first_name: '',
cpf: '',
rg: '',
last_name: '',
email: '',
phone: '',
address: '',
id: 0
});
const props = defineProps({
  open: {
    type: Boolean,
    default: false
  },
  customerToUpdate: {
    type: Object as () => Customer | null,
    default: null
  }
})

const { open, customerToUpdate } = toRefs(props);

// Verify if the cpf already exists
watch(() => form.cpf, async (newValue) => {
    if (newValue.length < 11 || form.first_name) return;
    try {
        const response = await API.get(`customer/read/${newValue}/`);
        if (Object.entries(response.data).length > 0) {
            alert('CPF já cadastrado');
            let customerData = (response.data) as Customer;
            form.first_name = customerData.first_name;
            form.last_name = customerData.last_name;
            form.rg = customerData.rg;
            form.email = customerData.email;
            form.phone = customerData.phone;
            form.address = customerData.address;
            form.id = customerData.id;

        }
    } catch (error) {
        console.error(error);
    }
});

watchEffect(()=>{
  if(customerToUpdate.value){
    form.first_name = customerToUpdate.value.first_name;
    form.last_name = customerToUpdate.value.last_name;
    form.rg = customerToUpdate.value.rg;
    form.email = customerToUpdate.value.email;
    form.phone = customerToUpdate.value.phone;
    form.address = customerToUpdate.value.address;
    form.id = customerToUpdate.value.id;
    form.cpf = customerToUpdate.value.cpf;
  }
})

// Save customer
const saveCustomer = () => {
  API.post('customer/create/',{...form}).then((response) => {
      if ([200, 201].includes(response.request.status)) {
        alert('Dados cadastrados com sucesso!');
      }
  });
}

const handleOpen = (value: boolean) => {
  form.first_name = '';
  form.cpf = '';
  form.first_name = '';
  form.rg = '';
  form.last_name = '';
  form.email = '';
  form.phone = '';
  form.address = '';
  form.id = 0;
  emit('update:open', value);
}

</script>
<template>
  <GeneralPopUp
    :open="open"
    max-width="1200px"
    max-height=""
    @update:open="handleOpen"
  >
    <template #content>
      <form class="w-full p-3 space-y-6">
        <div class="flex justify-center w-full">
          <span class="text-3xl">Cadastrar novo cliente</span>
        </div>
        <div class="grid grid-cols-6 items-center">
          <label
            class="text-xl font-semibold text-slate-500 col-span-1 mr-3 justify-self-end"
            for="cpf"
          >CPF</label>
          <input
            id="cpf"
            v-model="form.cpf"
            type="text"
            class="p-2 border col-span-5 border-gray-300 rounded-md w-full h-14"
          >
        </div>
        <div class="grid grid-cols-6 items-center">
          <label
            class="text-xl text-slate-500 font-semibold col-span-1 mr-3 justify-self-end"
            for="name"
          >Nome</label>
          <input
            id="name"
            v-model="form.first_name"
            type="text"
            class="p-2 border col-span-5 border-gray-300 rounded-md w-full h-14"
          >
        </div>
        <div class="grid grid-cols-6 items-center">
          <label
            class="text-xl font-semibold text-slate-500 col-span-1 mr-3 justify-self-end"
            for="last_name"
          >Sobrenome</label>
          <input
            id="last_name"
            v-model="form.last_name"
            type="text"
            class="p-2 border col-span-5 border-gray-300 rounded-md w-full h-14"
          >
        </div>
        <div class="grid grid-cols-6 items-center">
          <label
            class="text-xl font-semibold text-slate-500 col-span-1 mr-3 justify-self-end"
            for="rg"
          >RG</label>
          <input
            id="rg"
            v-model="form.rg"
            type="text"
            class="p-2 border col-span-5 border-gray-300 rounded-md w-full h-14"
          >
        </div>
        <div class="grid grid-cols-6 items-center">
          <label
            class="text-xl font-semibold text-slate-500 col-span-1 mr-3 justify-self-end"
            for="email"
          >Email</label>
          <input
            id="email"
            v-model="form.email"
            type="email"
            class="p-2 border col-span-5 border-gray-300 rounded-md w-full h-14"
          >
        </div>
        <div class="grid grid-cols-6 items-center">
          <label
            class="text-xl font-semibold text-slate-500 col-span-1 mr-3 justify-self-end"
            for="phone"
          >Telefone</label>
          <input
            id="phone"
            v-model="form.phone"
            type="text"
            class="p-2 border col-span-5 border-gray-300 rounded-md w-full h-14"
          >
        </div>
        <div class="grid grid-cols-6 items-center">
          <label
            class="text-xl font-semibold text-slate-500 col-span-1 mr-3 justify-self-end"
            for="address"
          >Endereço</label>
          <input
            id="address"
            v-model="form.address"
            type="text"
            class="p-2 border col-span-5 border-gray-300 rounded-md w-full h-14"
          >
        </div>
        <div class="flex justify-center space-x-2">
          <VButton
            max-widht=""
            label-text="Cancelar"
            custom-class="w-96 items-center text-center"
            class="!bg-red-500 hover:!bg-red-700"
            @click="handleOpen(false)"
          />
          <VButton
            custom-class="w-96 items-center text-center"
            label-text="Salvar"
            @click="saveCustomer"
          />
        </div>
      </form>
    </template>
  </GeneralPopUp>
</template>