<script lang='ts'>
export default {
  name: "AppBar"
}
</script>
<script setup lang='ts'>
import { computed, ref } from 'vue'
import {useRoute } from "vue-router"
import LogOutButton from '@/views/login/LogOutButton.vue';
const route = useRoute()
const hideMenu = ref<boolean>(true)
const items = ref<Array<{title:string, value:string, active:boolean}>>([
    {
      title: 'Início',
      value: '/',
      active: false,
    },
    {
      title: 'Orçamentos',
      value: '/orders',
      active: false,
    },
    {
      title: 'Vendas',
      value: '/purchases',
      active: false,
    },
    {
      title: 'Clientes',
      value: '/customers',
      active: false,
    },
    {
      title: 'Estoque',
      value: '/stock',
      active: false,
    },
    {
      title: 'Configurações',
      value: '/settings',
      active: false,
    },
]);
const options = computed(() => items.value.map(item => {
  item.active = route.path === item.value
  return {...item, active: item.active}
}));
</script>
<template>
  <nav class="flex items-center justify-between flex-wrap bg-slate-600 p-6">
    <div class="flex items-center flex-shrink-0 text-white mr-6">
      <svg
        class="fill-current h-8 w-8 mr-2"
        width="54"
        height="54"
        viewBox="0 0 54 54"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.5 22.1c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05zM0 38.3c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05z"
        />
      </svg>
      <span class="font-semibold text-3xl tracking-tight">Tifyy</span>
    </div>
    <div class="block lg:hidden">
      <button
        class="flex items-center px-3 py-2 border rounded text-slate-200 border-slate-400 hover:text-white hover:border-white"
        @click="hideMenu = !hideMenu"
      >
        <svg
          class="fill-current h-3 w-3"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </button>
    </div>
    <div
      class="w-full md:block flex-grow lg:flex lg:items-center lg:w-auto"
      :class="{ 'hidden': hideMenu}"
    >
      <div class="text-xl lg:flex-grow md:space-x-8">
        <a
          v-for="option in options"
          :key="option.value"
          :href="option.value"
          class="block uppercase mt-4 lg:inline-block lg:mt-0 text-slate-200 hover:text-white mr-4"
          :class="{ 'text-white': option.active }"
        >
          {{ option.title }}
        </a>
      </div>
      <div>
        <LogOutButton class="w-full mt-4 md:w-22" />
      </div>
    </div>
  </nav>
</template>