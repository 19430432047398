<template>
  <button
    class="text-red font-bold hover:text-white hover:bg-red-600 px-4 py-2 rounded-md bg-white border border-red hover:border-red-600 hover:border-2 hover:shadow-md transition duration-300 ease-in-out text-"
    @click="logout"
  >
    Sair
  </button>
</template>

<script setup lang="ts">
const logout = () => {
    localStorage.clear();
    location.reload()
};
</script>
<script lang="ts">
export default {
  name: 'LogOutButton',
}
</script>
