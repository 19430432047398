import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import OrdersView from "@/views/OrdersView.vue";
import PurchasesView from "@/views/PurchasesView.vue";
import CustomersView from "@/views/customers/index.vue";
import StockView from "@/views/stock/index.vue";
import LoginView from "@/views/login/index.vue";
import Configurations from "@/views/configurations/index.vue"
import { useUserStore } from "@/store/user";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Caixa",
      requiresAuth: true,
    },
  },
  {
    path: "/orders",
    name: "about",
    component: OrdersView,
    meta: {
      title: "Orçamentos",
      requiresAuth: true,
    },
      
  },
  {
    path: "/purchases",
    name: "purchases",
    component: PurchasesView,
    meta: {
      title: "Compras",
      requiresAuth: true,
    },
      
  },
  {
    path: "/customers",
    name: "customers",
    component: CustomersView,
    meta: {
      title: "Clientes",
      requiresAuth: true,
    },
  },
  {
    path: "/stock",
    name: "stock",
    component: StockView,
    meta: {
      title: "Estoque",
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      title: "Login",
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: Configurations,
    meta: {
      title: "Settings",
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const userStore = useUserStore();
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!userStore.isLoggedIn) {
      
      next({ path: '/login' });
    } else {
      next(); 
    }
  } else {
    next(); 
  }
});
export default router;
