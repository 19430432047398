<script lang='ts'>
export default {
  name: 'CreateAndUpdate',
}
</script>
<script setup lang='ts'>
import GeneralPopUp from '@/components/GeneralPopUp.vue';
import { computed, reactive } from 'vue';
import { Product } from '@/interfaces/api';
import VButton from '@/components/VButton.vue';
import API from '@/plugins/axios';
import { toRefs } from 'vue';
import { watchEffect } from 'vue';
import { ref } from 'vue';
import { useUserStore } from '@/store/user';
const emit = defineEmits(['update:open']);
const currentOperation = ref('post');
const { userGroups, isAdmin } = useUserStore();
const canUpdatePrice = computed(() => {
  return userGroups?.includes('admin') || userGroups?.includes('managers') || isAdmin;
});
const form = reactive<Product>({
  id: 0,
  name: '',
  description: '',
  price_sale: 0,
  active: true,
  created_at: '',
  stock_quantity: 0,
});
const props = defineProps({
  open: {
    type: Boolean,
    default: false
  },
  productToUpdate: {
    type: Object as () => Product | null,
    default: null
  }
})

const { open, productToUpdate } = toRefs(props);

// Save customer
const saveProduct = () => {
  if (currentOperation.value === 'post') {
    API.post('product/create/', { ...form }).then((response) => {
      if ([200, 201].includes(response.request.status)) {
        alert('Dados cadastrados com sucesso!');
      }
    });
  } else {
    API.put(`product/update/${productToUpdate?.value?.id}/`, { ...form }).then((response) => {
      if ([200, 201].includes(response.request.status)) {
        alert('Dados atualizados com sucesso!');
      }
    });
  }
}

watchEffect(() => {
  if (productToUpdate.value) {
    form.id = productToUpdate.value.id;
    form.name = productToUpdate.value.name;
    form.description = productToUpdate.value.description;
    form.price_sale = productToUpdate.value.price_sale;
    form.stock_quantity = productToUpdate.value.stock_quantity;
    form.active = productToUpdate.value.active;
    currentOperation.value = 'put'
  } else {
    currentOperation.value = 'post';
  }
})
const handleOpen = (value: boolean) => {
  form.name = '';
  form.description = '';
  form.price_sale = 0;
  form.stock_quantity = 0;
  form.active = true;
  emit('update:open', value);
}

</script>
<template>
  <GeneralPopUp
    :open="open"
    max-width="1200px"
    max-height=""
    @update:open="handleOpen"
  >
    <template #content>
      <form class="w-full p-3 space-y-6">
        <div class="flex justify-center w-full">
          <span class="text-3xl">Cadastrar novo produto</span>
        </div>
        <div
          v-if="!canUpdatePrice"
          class="items-center space-x-2 grid grid-cols-6 text-sm"
        >
          <div class="col-span-1 justify-self-end mt-0 mr-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-6 h-6"
            >
              <path
                fill-rule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 0 1 .67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 1 1-.671-1.34l.041-.022ZM12 9a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <span class="text-slate-400 text-sm col-span-5"> Você não tem permissão pra alterar preço.</span>
        </div>
        <div class="grid grid-cols-6 items-center">
          <label
            class="text-xl font-semibold text-slate-500 col-span-1 mr-3 justify-self-end"
            for="cpf"
          >Nome</label>
          <input
            id="cpf"
            v-model="form.name"
            type="text"
            class="p-2 border col-span-5 border-gray-300 rounded-md w-full h-14"
          >
        </div>
        <div class="grid grid-cols-6 items-center">
          <label
            class="text-xl text-slate-500 font-semibold col-span-1 mr-3 justify-self-end"
            for="name"
          >Descrição</label>
          <input
            id="name"
            v-model="form.description"
            type="text"
            class="p-2 border col-span-5 border-gray-300 rounded-md w-full h-14"
          >
        </div>
        <div class="grid grid-cols-6 items-center">
          <label
            class="text-xl text-slate-500 font-semibold col-span-1 mr-3 justify-self-end"
            for="name"
          >Preço de
            venda</label>
          <input
            id="name"
            v-model="form.price_sale"
            :disabled="!canUpdatePrice"
            type="text"
            :class="{ 'bg-gray-200 cursor-not-allowed': !canUpdatePrice }"
            class="p-2 border col-span-5 border-gray-300 rounded-md w-full h-14"
          >
        </div>
        <div class="grid grid-cols-6 items-center">
          <label
            class="text-xl text-slate-500 font-semibold col-span-1 mr-3 justify-self-end"
            for="name"
          >Quantidade em
            estoque</label>
          <input
            id="name"
            v-model="form.stock_quantity"
            type="number"
            class="p-2 border col-span-5 border-gray-300 rounded-md w-full h-14"
          >
        </div>
        <div class="grid grid-cols-8 items-center">
          <label
            class="text-xl text-slate-500 font-semibold col-span-1 mr-3 justify-self-end"
            for="name"
          >Ativo</label>
          <input
            id="name"
            v-model="form.active"
            type="checkbox"
            class="p-2 border col-span-1 border-gray-300 rounded-md w-full h-5"
          >
        </div>

        <div class="flex justify-center space-x-2">
          <VButton
            max-widht=""
            label-text="Cancelar"
            custom-class="w-96 items-center text-center"
            class="!bg-red-500 hover:!bg-red-700"
            @click="handleOpen(false)"
          />
          <VButton
            custom-class="w-96 items-center text-center"
            :label-text="currentOperation === 'post' ? 'Cadastrar' : 'Atualizar'"
            @click="saveProduct"
          />
        </div>
      </form>
    </template>
  </GeneralPopUp>
</template>