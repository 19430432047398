import { format } from "date-fns"
import { ptBR } from "date-fns/locale"
export const firstDayOfMonth = () => {
    const date = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    return format(date, "yyyy-MM-dd")
  }

export const currentMonth = () => {
  return format(new Date(), "MMMM", { "locale": ptBR })
  }

export const getSpelledDate = (date: string) => {
  return format(new Date(date), 'EEEE, d MMMM yyyy', {locale: ptBR})
}