<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang='ts'>
import API from "@/plugins/axios"
import { Product } from "@/interfaces/api"
import { ref, onMounted, watch } from 'vue'
import VButton from "@/components/VButton.vue"
import CreateAndUpdate from "./CreateAndUpdate.vue"
import { useUserStore } from "@/store/user"
import { EDIT_STOCK, CREATE_STOCK } from "@/constants"
import { useFocus } from '@vueuse/core'

const products = ref<Product[]>([])
const search = ref("")
const searchInput = ref<HTMLInputElement | null>(null)
const openCreateAndUpdate = ref<boolean>(false)
const productToUpdate = ref<Product | null>(null)
const { userPermissions } = useUserStore()

const canCreateProduct = CREATE_STOCK.some((requirerPermission) => userPermissions?.includes(requirerPermission))
const canEditProduct = EDIT_STOCK.some((requirerPermission) => userPermissions?.includes(requirerPermission))
const queryCustomersApi = (search: string = "") => {
  API.get('product/products/active/',{
    params: { search: search},
  })
    .then((response) => {
      products.value = (response.data.results as Product[])
    })
    .catch((error) => {
      console.error(error)
    })
}

onMounted(() => {
  queryCustomersApi()
})
useFocus(searchInput, { initialValue: true })
watch(search, (newValue) => {
  queryCustomersApi(newValue)
})

const updateProduct = (product: Product) => {
  openCreateAndUpdate.value = true
  productToUpdate.value = product
}

</script>
<template>
  <v-container>
    <!-- General information-->
    <div class="md:flex md:justify-between text-slate-800 p-3">
      <span class="text-6xl">Produtos</span>
      <span class="text-xl">
        <VButton
          v-if="canCreateProduct"
          class="w-full md:w-auto !ml-0"
          label-text="Cadastrar novo produto"
          @click="openCreateAndUpdate = true"
        >
          <template #icon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 text-white"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>

          </template>
        </VButton>
      </span>
    </div> <hr>
    <div>
      <input
        ref="searchInput"
        v-model="search"
        type="text"
        class="w-full p-4 border-b-2 border-slate-300 border"
        placeholder="Pesquisar produto..."
      >
    </div>
    <div class="text-start mt-4 w-full">
      <div
        v-for="product in products"
        :key="product.id"
        class="p-4 border-b-2 border-slate-300  bg-slate-100 w-full mb-3"
      >
        <div class="space-y-1 md:flex md:justify-between">
          <div>
            <div>
              <span class="text-3xl text-slate-600 uppercase"><strong>{{ product.name }}</strong></span>
            </div>
            <div class="space-y-2 text-xl mt-5">
              <p v-if="product.description">
                Descrição: <span class="font-bold">{{ product.description }}</span>
              </p>
              <p v-if="product.price_sale">
                Preço: <span class="font-bold">R$ {{ product.price_sale }}</span>
              </p>
              <p v-if="product.price_sale">
                Quantidade em estoque: <span class="font-bold">{{ product.stock_quantity }}</span>
              </p>
            </div>
          </div>        
          <div>
            <VButton
             
              v-if="canEditProduct"
              class="w-full ml-0"
              label-text="Atualizar Produto"
              @click="() => updateProduct(product)"
            >
              <template #icon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                  />
                </svg>
              </template>
            </VButton>
          </div>
        </div>
      </div>
    </div>
  </v-container>
  <CreateAndUpdate
    :open="openCreateAndUpdate"
    :product-to-update="productToUpdate"
    @update:open="(value: boolean) => openCreateAndUpdate = !openCreateAndUpdate"
  />
</template>
