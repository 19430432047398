import axios, { AxiosInstance,  AxiosResponse} from "axios"


const API: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_DEFAULT_URL,
})

API.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token")

    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

API.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error) => {
    console.log(error)
  }
);

export default API
