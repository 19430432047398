<script setup lang="ts">
import API from "@/plugins/axios"
import { computed, ref, nextTick, watchEffect, watch } from "vue"
import { Product, ProductOrder, Customer } from "@/interfaces/api"
import { resultExtractor } from "@/utils/api"
import QuantityPopUp from "@/components/QuantityPopUp.vue"
import CustomerApi from "@/api/customer"
import { useUserStore } from "@/store/user"
import SimplePopUp from "@/components/SimplePopUp.vue"
import { useDebounceFn } from "@vueuse/core"
import { onKeyStroke } from '@vueuse/core'
const userStore = useUserStore()
const autocomplete = ref<HTMLElement>()
const openQuantityDialog = ref(false)
const search = ref("")
const searchCustomer = ref("")
const items = ref<Array<{ title: string; value: string | number }>>([]) // Sample items for the autocomplete
const customerItems = ref<Array<{ title: string; value: string | number }>>([]) // Sample items for the autocomplete
const productsOnSale = ref<Array<ProductOrder>>([]) // Selected items
const productsList = ref<Array<Product>>([]) // List of products
const currentSelectedProduct = ref<Product>() // Current selected product
const currentSelectQuantity = ref(0) // Current selected quantity
const currentSelectedCustomer = ref<Customer | undefined>() // Current selected customer
const customersFromApi = ref<Customer[]>([]) // Customers from the API
const openConfirmTypePopUp = ref(false)
const discount = ref(0)
const productAlreadySelected = (id: number) => {
  return productsOnSale.value.some((item) => item.id === id)
}

// Add product to the list of products on sale
watchEffect(() => {

  if (!openQuantityDialog.value && currentSelectedProduct.value) {
    if (currentSelectQuantity.value === 0) return
    let product = {
      ...currentSelectedProduct.value,
      quantity: Number(currentSelectQuantity.value),
    }
    currentSelectedProduct.value = undefined
    if (productAlreadySelected(product.id)) {
      const index = productsOnSale.value.findIndex(
        (item) => item.id === product.id
      )
      productsOnSale.value[index].quantity = product.quantity
    } else {
      productsOnSale.value.push(product)
    }
    currentSelectedProduct.value = undefined
    autocomplete.value?.blur()

    // Reset the current selected product
    nextTick(() => {
      search.value = ""
      autocomplete.value?.focus()
    })
  }
})

const total = computed(() => {
  return productsOnSale.value.reduce((acc, item) => {
    acc = Number(acc)
    return Number(
      (acc + Number(item.price_sale) * Number(item.quantity)).toFixed(2)
    )
  }, 0)
})

// Select the product from the list
function onSelect(item: string) {
  if (!item) return
  currentSelectedProduct.value = getProductDetails(Number(item))
  if (currentSelectedProduct.value?.stock_quantity <= 0) {
    let continue_ = confirm("Produto sem estoque, deseja continuar?")
    if (!continue_) {
      currentSelectedProduct.value = undefined

    } else {
      openQuantityDialog.value = true
    }
  } else {
    openQuantityDialog.value = true
  }
}

// On customer select
function onCustomerSelect(item: string) {
  if (!item) return
  currentSelectedCustomer.value = customersFromApi.value.find(
    (customer: Customer) => customer.id == Number(item)
  )
}

// Get the product details from the list
const getProductDetails = (id: number): Product => {
  return productsList.value.find((item: Product) => item.id === id) as Product
}

// Update the list of options for the autocomplete
const updateListOptions = async (newValue: string) => {
  const { data } = await API.get("/product/products/active/", {
    params: { search: newValue },
  })

  productsList.value = resultExtractor(data) as Product[]

  items.value = productsList.value.map((item: Product) => {
    return {
      title: `${item.name} - Qtd: ${item.stock_quantity} | Preço: R$ ${item.price_sale}`,
      value: item.id,
    }
  })
}

// Make the query to the API
const onSearch = useDebounceFn((newValue: string) => {
  if (newValue) updateListOptions(newValue)
}, 500)

// Get the input from the customer pop-up and create the order
const onCustomerSearch = (newValue: string) => {
  CustomerApi.filterCustomers({ search: newValue }).then((data) => {
    // Saves information for reference and avoid another call to backend
    customersFromApi.value = data
    // Mounts options for the autocomplete
    customerItems.value = data.map((item: Customer) => {
      return {
        title: `${item.first_name} - ${item.email}`,
        value: item.id,
      }
    })
  })
}

// Create the order making a post request to the API
const createOrder = async (directPurchase: boolean = false) => {
  const order = {
    customer: currentSelectedCustomer?.value?.id,
    discount: discount.value,
    products: productsOnSale.value.map((item) => {
      return {
        product: item.id,
        quantity: item.quantity,
      }
    }),
  }
  const result = await API.post("/order/create-order", order)
  if (result.status === 201) {
    if (directPurchase) {
      savePurchase(result.data.id)
    } else {
      alert("Pedido criado com sucesso!")
      location.reload()
    }
    currentSelectedCustomer.value = undefined
    productsOnSale.value = []
  }
}

// Open a window to get the customer details
onKeyStroke('F4', (e) => {
  e.preventDefault()
  openNewWindow()
})
function openNewWindow() {
    window.open('/customers', '_blank', 'left=400,width=1200,height=800');
}

const savePurchase = (orderId: number) => {
  API.post("purchase/save-purchase", { order_id: orderId })
    .then(() => {
      alert("Venda realizada com sucesso!")
      location.reload()
    })
    .catch((e) => {
      console.error(e)
    })
}

const updateQuantity = (quantity: number) => {
  currentSelectQuantity.value = quantity
}

watch(()=>discount.value, (newValue) => {
  if (newValue > 10) {
    discount.value = 10
    alert("Desconto máximo de 10%");
  }
})

const user = computed(() => userStore.currentUser?.username)
</script>

<template>
  <v-container>
    <div class="flex justify-end items-center mb-3">
      <div class="text-xl font-semibold text-slate-600 mr-4">
        Usuário atual:
      </div>
      <input
        v-model="user"
        variant="outlined"
        class="max-h-12 text-xl border border-slate-300 rounded-lg p-2 w-1/4 text-center cursor-not-allowed"
        :disabled="true"
      >
    </div>
    <v-autocomplete
      ref="autocomplete"
      v-model="searchCustomer"
      :label="`Buscar cliente`"
      placeholder="Cliente"
      autofocus
      auto-select-first
      clearable
      hide-no-data
      :items="customerItems"
      @update:search="onCustomerSearch"
      @update:model-value="onCustomerSelect"
    />
    <v-card-title class="bg-slate-100 text-start text-black">
      <div class="d-flex justify-space-between">
        <span class="text-h3">R$ {{ total }}</span>
      </div>
    </v-card-title>
    <div v-if="currentSelectedCustomer">
      <v-autocomplete
        ref="autocomplete"
        v-model="search"
        :label="`Buscar produto`"
        placeholder="Buscar produto"
        autofocus
        auto-select-first
        clearable
        hide-no-data
        :items="items"
        @update:search="onSearch"
        @update:model-value="onSelect"
      />
      <v-card class="w-full">
        <table class="w-100 mx-auto elevation-1">
          <thead>
            <tr>
              <th class="text-start pa-2">
                Cod
              </th>
              <th class="text-start pa-2">
                Nome
              </th>
              <th class="text-start pa-2">
                Qtd
              </th>
              <th class="text-start pa-2">
                Pc unit
              </th>
              <th class="text-start pa-2">
                Pc total
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="product in productsOnSale"
              :key="product.id"
            >
              <td class="text-start pa-2">
                {{ product.id }}
              </td>
              <td class="text-start pa-2">
                {{ product.name }}
              </td>
              <td class="text-start pa-2">
                {{ product.quantity }}
              </td>
              <td class="text-start pa-2">
                R$ {{ product.price_sale }}
              </td>
              <td class="text-start pa-2">
                R$
                {{
                  (
                    Number(product.price_sale) * Number(product.quantity)
                  ).toFixed(2)
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </v-card>
    </div>
    <div>
      <div class="flex mb-3 mt-4">
        <input
          v-if="currentSelectedCustomer"
          v-model="discount"
          type="number"
          max="10"
          class="max-h-12 focus:outline-slate-600 focus:outline-4 text-xl border border-slate-300 rounded-lg p-2 w-full"
          placeholder="Desconto"
        >
      </div>
    </div>
    <div>
      <button
        v-if="productsOnSale.length > 0"
        class="w-full uppercase bg-slate-400 mt-4 h-12 rounded-sm hover:bg-slate-700 text-white"
        @click="openConfirmTypePopUp = true"
      >
        Fechar orçamento
      </button>
    </div>
    <quantity-pop-up
      :dialog="openQuantityDialog"
      :product="(currentSelectedProduct) as Product"
      @update:quantity="updateQuantity"
      @update:dialog="(val) => (openQuantityDialog = val)"
    />
    <SimplePopUp
      v-model="openConfirmTypePopUp"
      max-height="h-64"
    >
      <template #content>
        <div class="space-y-6 w-9/12">
          <button
            class="w-full h-12 bg-slate-600 focus:outline focus:outline-4 focus:outline-orange-500 rounded-sm hover:bg-slate-700 text-white"
            @click="createOrder()"
            @keydown:enter="createOrder()"
          >
            Orçamento
          </button>
          <button
            class="focus:outline focus:outline-4 focus:outline-orange-500 w-full h-12 bg-slate-400 rounded-sm hover:bg-slate-700 text-white"
            @click="() => createOrder(true)"
            @keydown:enter="() => createOrder(true)"
          >
            Venda direta
          </button>
        </div>
      </template>
    </SimplePopUp>
  </v-container>
</template>
