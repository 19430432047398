import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return ($setup.product?.name)
    ? (_openBlock(), _createBlock(_component_v_dialog, {
        key: 0,
        modelValue: $setup.dialog,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.dialog) = $event)),
        persistent: "",
        "max-width": "290"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.product.name) + " | QTD: " + _toDisplayString($setup.product.stock_quantity), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_text_field, {
                    modelValue: $setup.quantity,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.quantity) = $event)),
                    autofocus: true,
                    label: "Quantity",
                    type: "number",
                    min: "1",
                    onKeyup: [
                      _withKeys($setup.confirmQuantity, ["enter"]),
                      _cache[1] || (_cache[1] = _withKeys(($event: any) => ($setup.closeDialog('cancel')), ["esc"]))
                    ]
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.closeDialog('cancel')))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Cancelar ")
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"]))
    : _createCommentVNode("v-if", true)
}