<script lang="ts">
export default {
  name: 'VButton',
}
</script>
<script setup lang='ts'>
import { toRefs } from 'vue';

const props = defineProps({
  labelText: {
    type: String,
    required: true
  },
  customClass: {
    type: String,
    required: false,
    default: ''
  },
  customLabelClass: {
    type: String,
    required: false,
    default: ''
  },
  isLoading: {
    type: Boolean,
    default: false
  }
})

const { labelText, customClass } = toRefs(props)
</script>
<template>
  <button
    :class="customClass"
    class="bg-slate-500 m-3 p-4 rounded-md hover:bg-slate-700 cursor-pointer flex justify-start space-x-2 items-center"
  >
    <slot name="icon" />
    <div class="flex items-center space-x-2">
      <svg
        v-if="isLoading"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6 animate-spin text-white"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
        />
      </svg>
      <span
        class="text-white text-center"
        :class="customLabelClass"
      >{{ labelText }}</span>
    </div>
  </button>
</template>