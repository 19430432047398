<script setup lang='ts'>
import GeneralPopUp from '@/components/GeneralPopUp.vue';
import { Customer, Purchase } from '@/interfaces/api';
import { getSpelledDate } from '@/utils/helpers';
import ExpendableComponent from "@/components/ExpendableComponent.vue"
import API from '@/plugins/axios';
import { toRefs, ref } from 'vue';
import { watchEffect } from 'vue';

const emit = defineEmits(['update:open']);
const purchases = ref<Purchase[]>([]);
const props = defineProps({
  open: {
    type: Boolean,
    default: false
  },
  customer: {
    type: Object as () => Customer | null,
    default: null
  }
})

const { open, customer } = toRefs(props);


const handleOpen = (value: boolean) => {
  customer.value = null;
  emit('update:open', value);
}

watchEffect(() => {
  if (customer.value) {
    API.get('purchase/list', {params: {customer_id: customer?.value?.id}})
      .then((response) => {
        purchases.value = (response.data.results as Purchase[])
      })
      .catch((error) => {
        console.error(error)
      })
  }
})

</script>
<template>
  <GeneralPopUp
    :open="open"
    max-width="1200px"
    max-height=""
    @update:open="handleOpen"
  >
    <template #content>
      <div class="flex justify-center w-full">
        <span class="text-3xl mb-4">Últimas compras</span>
      </div>
      <div
        v-if="purchases.length > 0"
        class="w-full"
      >
        <div
          v-for="purchase in purchases"
          :key="purchase.id"
          class="p-4 border-b-2 border-slate-300"
        >
          <div class="uppercase text-xl font-semibold text-slate-500">
            {{ getSpelledDate(purchase.created_at) }}
          </div>          
          <div class="uppercase text-xl font-semibold text-slate-500">
            R${{ purchase.total_price }}
          </div>          
          <ExpendableComponent
            items-label="Produtos"
            :purchase-or-order="purchase"
          />
        </div>
      </div>
      <div v-else>
        <div class="p-4 text-red-700 text-xl font-bold">
          Nenhuma compra encontrada
        </div>
      </div>
    </template>
  </GeneralPopUp>
</template>