import API from "@/plugins/axios"
import { ref } from "vue"

const qrCode = ref<string | undefined>(undefined)

interface sessionParam {
  userName: string | undefined
}

class WhatsappAPI {
  private url: string
  private startSessionUrl: string
  private getQRCodeUrl: string
  private checkSessionUrl: string
  private userName: string | undefined
  public qrCode: string | undefined
  constructor() {
    this.url = process.env.VUE_APP_VENON_BOT_ENDPOINT as string
    this.startSessionUrl = `${this.url}/start-session`
    this.getQRCodeUrl = `${this.url}/get-qrcode`
    this.checkSessionUrl = `${this.url}/check-session`
    this.userName = undefined
  }

  async startWhatsappSession(params: sessionParam): Promise<void> {
    const data = {
      sessionName: params.userName,
    }
    if (params?.userName) {
      API.post(this.startSessionUrl, { ...data })
    }
  }

  async getLoginQRCode(params: sessionParam): Promise<string | undefined> {
    const result = await API.get(this.getQRCodeUrl, { params: params })
    if (result?.status === 404) return undefined
    else if (result?.status == 200) {
      return result?.data?.qrcode
    }
    return undefined
  }

  async authenticateWhatsapp(userName: string | undefined): Promise<string | undefined > {
    if (!userName) return;
    this.startWhatsappSession({
      userName: userName,
    })
    this.userName = userName
    await this.#startPollingQRCode()    
  }
  async #startPollingQRCode(interval: number = 4000, timeout: number = 60000) {
    const startTime = new Date().getTime()
    const gettingQRCode = setInterval(async () => {
      const now_ = new Date().getTime()
      if (now_ - startTime > timeout) {
        stopGettingQRCode()
      }
      try {
        qrCode.value = await this.getLoginQRCode({ userName: this.userName })
        if (qrCode.value) stopGettingQRCode()
      } catch (error) {
        console.error("Error fetching QR Code:", error)
        stopGettingQRCode()
      }
    }, interval)

    const stopGettingQRCode = () => {
      clearInterval(gettingQRCode)
      console.log("Stopped polling for QR code.")
    }    
  }

  async checkSession(userName: string | undefined): Promise<boolean> {
    if (!userName) return false;
    const result = await API.get(`${this.checkSessionUrl}/${userName}`)
    // const result = await API.get(`${this.checkSessionUrl}/session-name`)
    if (result?.status === 404) return false
    else if (result?.status == 200) {
      return true;
    }
    return false
  }

  async sendOrder(orderId: number) {
    const result = await API.post(`/send-whatsapp-message/`, {
      order_id: orderId
    })
    if (result.status === 200) {
      return true;
    } else {
      return false;
    }
  }
}

const whatsappService = new WhatsappAPI()
export const useWhatsapp = () => {
    return {
        whatsappService, 
        qrCode,
    }
}
