<script setup lang='ts'>
import API from "@/plugins/axios"
import { Purchase, Order } from "@/interfaces/api"
import { format } from 'date-fns'
import { ref, onMounted, watch } from 'vue'
import ExpendableComponent from "@/components/ExpendableComponent.vue"
import VButton from "@/components/VButton.vue"
import GeneralPopUp from "@/components/GeneralPopUp.vue"
import { computed } from "vue"
import SelectFilter from "@/components/SelectFilter.vue"
import { MONTHS } from "@/constants"
const purchases = ref<Purchase[]>([])


const currentMonth = ref<number>(new Date().getMonth())
function getMonthName(month: number) {
  return MONTHS[month]?.label
}


onMounted(() => {
  API.get('purchase/list', { params: { month_number: currentMonth.value }})
    .then((response) => {
      purchases.value = (response.data.results as Purchase[])
    })
    .catch((error) => {
      console.error(error)
    })
})

const orderDetail = ref<Order | null>();
function showOrderDetail(order: Order) {
  orderDetail.value = order;
}
const total = computed(() => {
  return purchases.value.reduce((acc, item) => {
    acc = Number(acc)
    return Number((acc + Number(item.total_price)).toFixed(2))
  }, 0)
})

watch(currentMonth, () => {
  API.get('purchase/list', { params: { month_number: currentMonth.value }})
    .then((response) => {
      purchases.value = (response.data.results as Purchase[])
    })
    .catch((error) => {
      console.error(error)
    })
})
</script>
<template>
  <v-container>
    <!-- General information-->
    <div class="md:grid md:grid-cols-12 text-slate-800 p-6 flex justify-start">
      <span class="md:text-6xl text-3xl md:col-span-1">R$</span>
      <span class="md:text-6xl text-3xl md:col-span-2 md:mr-0 mr-6">{{ total }}</span>      
      <span class="md:text-6xl text-3xl md:col-span-6 uppercase" />      
      <span class="md:text-6xl text-3xl md:col-span-2 uppercase">{{ getMonthName(currentMonth) }}</span>      
    </div> <hr>
    <hr>
    <div class="">
      <SelectFilter
        v-model="currentMonth"
        :placeholder="`Escolha o mês`"
        :options="MONTHS"
      />
    </div>
    <div class="text-start  w-full">
      <div
        v-for="purchase in purchases"
        :key="purchase.id"
        class="p-4  border-b-2 border-slate-300 md:flex md:justify-between"
      >
        <div class="p-6 md:flex w-full md:justify-between">
          <div class="">
            <div>
              <span class="text-3xl text-slate-600">VENDA <strong>0{{ purchase.id }}</strong></span>
            </div>
            <div class="space-y-1 w-full">
              <p v-if="purchase.customer_info">
                Cliente: {{ purchase.customer_info?.first_name }}
              </p>
              <p>Valor total: <span class="font-bold">R$ {{ purchase.total_price }}</span></p>
              <p>Desconto: {{ purchase.origin_order.discount || 0 }}%</p>
              <p>Data: {{ format(purchase.created_at, "dd-MM-yyyy") }}</p>
              <div v-if="purchase.product_info.length > 0">
                <ExpendableComponent :purchase-or-order="purchase" />
              </div>
            </div>
          </div>
          <div class="">
            <VButton
              class="w-full ml-0"
              label-text="Ver ordem"
              @click="() => showOrderDetail(purchase.origin_order)"
            />
          </div>
        </div>
      </div>
    </div>
    <GeneralPopUp
      :open="Boolean(orderDetail)"
      max-width="1200"
      @update:open="orderDetail = null"
    >
      <template #title>
        <div class="p-5">
          <div class="w-full">
            <span class="text-3xl">Orçamento</span>
            <hr>
          </div>
        </div>
      </template>
      <template #content>
        <div class="p-10 self-start w-full space-y-3">
          <div>
            <span class="font-bold">Total: </span><span class="text-slate-700 font-bold">R$ {{ orderDetail?.total_price
            }}</span>
          </div>
          <div>
            <span class="font-bold">Cliente: </span><span>{{ orderDetail?.customer?.first_name }}</span>
          </div>
          <div>
            <span class="font-bold">Data: </span><span>{{ format(orderDetail?.created_at || new Date(), "yyyy-MM-dd")
            }}</span>
          </div>
          <div>
            <span class="font-bold">Produtos:</span>
            <div class="space-y-2">
              <div
                v-for="product in orderDetail?.products"
                :key="product.id"
                class="border-b md:ml-20 border-slate-300 grid grid-cols-9  space-x-4"
              >
                <span class="col-span-2">{{ product.name }}</span>
                <span class="col-span-1">|</span>
                <span class="col-span-1">{{ product.quantity < 10 ? `0${product.quantity}` : product.quantity }}</span>
                <span class="col-span-1">|</span>
                <span class="col-span-4 text-slate-800 font-semibold">R$ {{ product.price_sale }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </GeneralPopUp>
  </v-container>
</template>
