<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang='ts'>
import { useWhatsapp } from "@/api/whatsapp"
import { useUserStore } from "@/store/user";
import { onMounted, ref } from "vue";

const { qrCode, whatsappService } = useWhatsapp()
const { currentUser } = useUserStore()
const processing = ref(false)
const userIsLoggedInOnWhatsapp = ref<boolean>(false);
onMounted(async () => {
  userIsLoggedInOnWhatsapp.value = await whatsappService.checkSession(currentUser?.username)
})

const connectWhatsapp = () => {  
    processing.value = true;
    whatsappService.authenticateWhatsapp(currentUser?.username)
}

</script>
<template>
  <v-container>
    <!-- General information-->
    <div class="space-y-7 text-start mt-10">
      <p class="text-2xl text-white p-4 w-full bg-slate-600">
        Whatsapp
      </p>
      <div class="text-start text-xl">
        Para enviar orçamentos e outras informações via whatsapp é necessário fazer authenticar com o seu
        aplicativo
      </div>
      <div>
        <div
          v-if="userIsLoggedInOnWhatsapp"
          class="w-full"
        >
          <p class="text-slate-600 text-xl">
            Usuário logado no whatsapp!
          </p>
        </div>
        <button
          v-else
          class="bg-slate-600 hover:bg-slate-500 text-white p-4 rounded"
          @click="connectWhatsapp"
        >
          Conectar Whatsapp
        </button>
      </div>
      <div
        class="flex justify-center items-center w-full"
      >
        <div
          v-if="processing && !qrCode"
          class="text-black text-3xl"
        >
          Processando...
        </div>
        <img
          v-if="qrCode"
          :src="qrCode"
          alt="WhatsApp QR Code"
        >
      </div>
    </div>
    <div />
  </v-container>
</template>
